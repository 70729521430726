/* eslint-disable */

jQuery(document).ready(function ($) {
  /* Replace break with an actual HTML break */
  jQuery(".text").each(function () {
    var text = jQuery(this).html();
    jQuery(this).html(text.replace(/break/g, "<br>"));
  });

  jQuery(".has-tooltip").click(function (e) {
    e.preventDefault();
  });

  $(".materiality-list li").each(function () {
    $(this).shorten({
      showChars: 210,
      moreText: "See more",
      lessText: "Less",
    });
  });

  /**
   * Toggle tooltips when viewport width changes
   */
  function handleMatchMedia(mql) {
    if (mql.matches) {
      $(".has-tooltip").click(function () {
        $(this).find(".tooltip").toggle();
      });
    }
  }

  var mediaQueryList = window.matchMedia("(max-width: 768px)");

  handleMatchMedia(mediaQueryList);

  if ("addEventListener" in mediaQueryList) {
    mediaQueryList.addEventListener("change", handleMatchMedia);
  } else {
    // Older versions of Safari don't support MediaQueryList.addEventListener()
    mediaQueryList.addListener(handleMatchMedia);
  }
});

/**
 * Materialtiy Finder Overview Table Fix
 *
 */

jQuery(".category").each(function () {
  jQuery(this).css("height", jQuery(this).parent().height());
});

/**
 * FSA Logistics
 *
 */

jQuery(".fsa-logistics").wrapInner('<div class="row"></div>');

/**
 * FSA Exam Menu
 *
 */

jQuery(
  '<div class="menu-dropdown-mobile"><select class="dropdown wide" autocomplete="off" onchange="location = this.value;"></select></div>'
).insertAfter(".fsa-menu-anchor");

jQuery(".fsa-menu-anchor ul li a").each(function () {
  jQuery(
    '<option value="' + jQuery(this).attr("href") + '">' + jQuery(this).html() + "</option>"
  ).appendTo(".menu-dropdown-mobile select");
});

if (typeof jQuery.prototype.niceSelect === "function") {
  jQuery(".menu-dropdown-mobile .dropdown").niceSelect({
    // inheritOriginalWidth : true
  });
}

jQuery(".menu-dropdown-mobile").on("change", "select", function () {
  var sortBy = jQuery(".dropdown").val();

  jQuery([document.documentElement, document.body]).animate(
    {
      scrollTop: jQuery(sortBy).offset().top,
    },
    500
  );
});

jQuery(".fsa-register .sasb-tabs .item .link").click(function () {
  jQuery(".fsa-register .sasb-tabs").attr("data-current-tab", jQuery(this).attr("data-href-hash"));
});

jQuery(function($) {
  $(document).on('nfFormSubmitResponse', handleFSAStudyGuideRequestFormSubmissions);

  /**
   * React to FSA study guide request form submissions (via Ninja Forms)
   * by opening the requested study guide in a new tab.
   */
  function handleFSAStudyGuideRequestFormSubmissions(event, data) {
    try {
      const {
        actions: { success_message },
        settings: { element_class, wrapper_class }
      } = data.response.data;

      // If the form or wrapper element classes include
      // "js-nf-sasb-open-blank", and the form has a success message action
      // that includes a link, then we will programmatically open that link
      // in a new tab.
      if (
        success_message &&
        (element_class.includes("js-nf-sasb-open-blank") || wrapper_class.includes("js-nf-sasb-open-blank"))
      ) { 
        const tmp = document.createElement('div');
        tmp.innerHTML = success_message;
        const link = tmp.querySelector("a[target='_blank']");
        if (link) {
          window.open(link.toString(), "_blank")
        }
      }
    } catch (error) {
      // Ignore
    }
  }
});